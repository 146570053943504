<template>
  <transition name="menu">
    <form-penomoransurat :is-edit="true"/>
  </transition>
</template>

<script>
import formPenomoransurat from './components/formPenomoranSurat'

export default {
  name: 'EditformPenomoransurat',
  components: { formPenomoransurat }
}
</script>

